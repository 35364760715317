import React from "react"
import { Link } from "gatsby"

import Version from "../utils/Version"
import "../styles/style.css"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header
  const isRoot = location.pathname === rootPath

  if (location.pathname === rootPath) {
    header = (
      <div>
        <h1 className="landing-title blog-title">
          <Link className="title" to={`/`}>
            {title.slice(0, title.length - 3)}
          </Link>
        </h1>
        <h2 className="landing-subtitle">
          <span>Randonnées.</span>
          <span>Nature.</span>
          <span>Partage.</span>
        </h2>
      </div>
    )
  } else {
    header = (
      <h3 className="blog-title">
        <Link className="title" to={`/`}>
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div className="blog-container">
      <div>
        <header className={isRoot ? "landing" : ""}>{header}</header>
        <main>{children}</main>
      </div>
      <footer className="main-footer">
        <span>
          ©<span title={Version.date}>2019 - {new Date().getFullYear()}</span> -
        </span>
        <span> Tous droits réservés -</span>
        <span>
          Built with{" "}
          <span role="img" aria-label="montagne">
            ⛰️
          </span>
        </span>
      </footer>
    </div>
  )
}

export default Layout
